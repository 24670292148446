import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import {
  PayPalScriptProvider,
  PayPalButtons,
  PayPalMessages,
} from "@paypal/react-paypal-js"
import { Card, Alert } from "antd"

import { getSdkParams, checkOutSdk } from "../../services/offer.service"

const PaypalOffer = ({ offer }) => {
  const history = useHistory()

  const [initialOptions, setInitialOptions] = useState(null)
  const [orderId, setOrderId] = useState("")
  const [errorStaus, setErrorStatus] = useState("")

  const getInitialOptions = async () => {
    try {
      const response = await getSdkParams(offer.id)
      if (response.success) {
        const {
          attribution_id,
          client_id,
          currency,
          intent,
          enable_funding,
          disable_funding,
          buyer_country,
          commit,
          components,
          debug,
          merchant_id,
          paypal_id,
        } = response.data

        setOrderId(paypal_id)
        setInitialOptions({
          clientId: client_id,
          currency: currency,
          intent,
          enableFunding: enable_funding,
          disableFunding: disable_funding,
          buyerCountry: buyer_country,
          commit,
          components,
          debug,
          merchantId: merchant_id,
          dataPartnerAttributionId: attribution_id,
        })
      }
    } catch (error) {
      setErrorStatus(error.message)
    }
  }

  useEffect(() => {
    if (offer?.status === "applied") {
      history.push("/loans")
    } else {
      getInitialOptions()
    }
    //eslint-disable-next-line
  }, [offer])

  return (
    <>
      {errorStaus && (
        <Alert
          message={errorStaus}
          type="error"
          showIcon
          closable
          style={{ marginBottom: 7, width: "100%", textAlign: "left" }}
        />
      )}
      <Card bodyStyle={{ margin: 10 }} style={{ width: "100%" }}>
        {initialOptions && (
          <PayPalScriptProvider options={initialOptions}>
            <PayPalMessages
              amount={offer.lead.amount}
              currency={initialOptions.currency}
              placement="product"
              style={{
                layout: "text",
                logo: { type: "inline" },
                text: {
                  size: 16,
                  align: "center",
                },
              }}
            />
            <div style={{ marginBottom: 30 }} />
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [
                    {
                      amount: {
                        value: offer.lead.amount,
                        currency_code: initialOptions.currency,
                      },
                    },
                  ],
                })
              }}
              onApprove={async (data, actions) => {
                try {
                  const response = await checkOutSdk(offer.id, "approve", data)
                  if (response.success) {
                    history.push("/loans")
                  }
                } catch (error) {
                  setErrorStatus(error.message)
                }
              }}
              onError={(err) => {
                setErrorStatus(err)
              }}
              onCancel={(data, actions) => {
                return orderId
              }}
            />
          </PayPalScriptProvider>
        )}
      </Card>
    </>
  )
}

export default PaypalOffer
