import Tracker from "@openreplay/tracker"

let tracker = null

export function startTrackerSession(projectKey) {
  tracker = new Tracker({
    projectKey,
    ingestPoint: "https://paypossible.us.openreplay.cloud/ingest",
  })
  tracker.start()
}

export function getTrackerSession() {
  return tracker
}
