import React from "react"
import ReactDOM from "react-dom"
import * as Sentry from "@sentry/react"
import { Integrations } from "@sentry/tracing"
import "./index.css"
import ReactGA from "react-ga"
import FullStory from "react-fullstory"
import { ConfigProvider } from "antd"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { BrowserRouter } from "react-router-dom"
import { IntlProvider } from "react-intl"
import posthog from "posthog-js"
import { PostHogProvider } from "posthog-js/react"

import { v4 as uuidv4 } from "uuid"
import "./utils/iframe-resizer"
import { startTrackerSession } from "./utils/openReplay"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { setTheme } from "./helpers/brand.helper"
import APP_THEME from "./utils/appBranding"
import FrontChat from "./utils/FrontChat"
import SEON from "./utils/SEON"
// import { Helmet } from "react-helmet";
import { AuthProvider } from "./hooks/useAuth"
import ScrollToTop from "./components/layout/ScrollToTop"
import English from "./lang/en.json"

import "antd/dist/antd.variable.min.css"

const USE_FRONT = true

const locale = navigator.language
let lang

/*
if (locale==="en") {
   lang = English;
} else {
   if (locale === "fr") {
       lang = French;
   } else {
       lang = Arabic;
   }
}
*/
lang = English

const domain = window.location.host.split(".")

const version =
  process.env.REACT_APP_ENV === "development"
    ? "paypossible"
    : domain.length >= 2
    ? domain[domain.length - 2]
    : "paypossible"

console.log(version)
const appTheme = version in APP_THEME ? version : "paypossible"
const theme = setTheme(APP_THEME[appTheme])

ConfigProvider.config({
  theme: {
    primaryColor: theme.color,
    infoColor: theme.color,
    successColor: theme.color,
  },
})

if ("parentIFrame" in window) {
  window.parentIFrame.autoResize(true)
}

if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "staging"
) {
  if (process.env.REACT_APP_SENTRY) {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.1,
      ignoreErrors: ["Non-Error promise rejection captured"],
    })
  }

  if (process.env.REACT_APP_OPENREPLAY) {
    const projectKey = process.env.REACT_APP_OPENREPLAY
    startTrackerSession(projectKey)
  }

  if (process.env.REACT_APP_GA) {
    ReactGA.initialize(process.env.REACT_APP_GA)
  }
}

function renderChat() {
  if (process.env.REACT_APP_ENV !== "production") return null
  if (USE_FRONT) {
    if (
      domain[1] === "citizensbank" ||
      domain[1] === "own" ||
      domain[1] === "flexshopper"
    )
      return null
    return <FrontChat chatId={process.env.REACT_APP_FRONT} />
  }
  return (
    <Helmet>
      <script
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=816a0935-6d70-48e5-b57c-00ec428696f2"
      >
        {" "}
      </script>
    </Helmet>
  )
}

function renderFullStory() {
  if (process.env.REACT_APP_FE !== "flexshopper") return null
  window["_fs_run_in_iframe"] = true
  return <FullStory org={process.env.REACT_APP_FULL_STORY} />
}

function renderHelmet() {
  switch (theme.title) {
    case "citizens":
      return (
        <Helmet>
          {/* <link rel="shortcut icon" href="payPossibleFav.ico" /> */}
          <meta
            name="description"
            content="Citizens Pay Customer Application"
          />
          <link rel="apple-touch-icon" href="enableTouchIcon.png" />
          <title>Apply | Citizens Pay</title>
        </Helmet>
      )
    case "enablefinancing":
      return (
        <Helmet>
          <link rel="shortcut icon" href="enableFav.ico" />
          <meta
            name="description"
            content="Enable Financing Customer Application"
          />
          <link rel="apple-touch-icon" href="enableTouchIcon.png" />
          <title>Apply | Enable Financing</title>
        </Helmet>
      )
    case "eazeconsulting":
      return (
        <Helmet>
          <link rel="shortcut icon" href="eazeconsultingFav.ico" />
          <meta
            name="description"
            content="Eaze Consulting Customer Application"
          />
          <link rel="apple-touch-icon" href="/logos/eazeLogo.png" />
          <title>Apply | Eaze Consulting</title>
        </Helmet>
      )
    case "ownlease":
      return (
        <Helmet>
          <link rel="shortcut icon" href="ownLeaseFav.ico" />
          <meta name="description" content="Own.Lease Customer Application" />
          <link rel="apple-touch-icon" href="ownLeaseTouchIcon.png" />
          <title>Apply | Own.Lease</title>
        </Helmet>
      )
    case "flexshopper":
      return (
        <Helmet>
          <link rel="shortcut icon" href="flexShopperFav.ico" />
          <meta name="description" content="FlexShopper Customer Application" />
          <title>Apply | FlexShopper</title>
        </Helmet>
      )
    case "bolsterhardware":
      return (
        <Helmet>
          <meta
            name="description"
            content="Bolsterhardware Customer Application"
          />
          <link rel="apple-touch-icon" href="enableTouchIcon.png" />
          <title>Apply | Bolsterhardware</title>
        </Helmet>
      )
    case "paypossible":
      return (
        <Helmet>
          <link rel="shortcut icon" href="payPossibleFav.ico" />
          <meta name="description" content="PayPossible Customer Application" />
          <link rel="apple-touch-icon" href="payPossibleTouchIcon.png" />
          <title>Apply | PayPossible</title>
        </Helmet>
      )
    default:
      return null
  }
}

function RenderPostHog({ children }) {
  if (!process.env.REACT_APP_POSTHOG_KEY) return children
  posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
    api_host: process.env.REACT_APP_POSTHOG_HOST,
    person_profiles: "identified_only",
  })
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>
}

function renderSEON() {
  const sessionId = uuidv4()
  console.debug("SEON Session ID", sessionId)
  sessionStorage.setItem("seonSessionId", sessionId)
  return <SEON sessionId={sessionId} />
}

/*
                    <script type="text/javascript">
                        var session_id = "asdf-asdf-asdf-asdf";
                        threatmetrix.profile("doodles.kewl", "rz31aez3", session_id);
                    </script>
*/
// {renderHelmet()}
//    <React.StrictMode> - removed strict mode to appead error

ReactDOM.render(
  <RenderPostHog>
    <IntlProvider locale={locale} messages={lang}>
      <HelmetProvider>
        {renderHelmet()}
        {renderChat()}
        {renderFullStory()}

        {renderSEON()}
        <BrowserRouter>
          <ScrollToTop />
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </HelmetProvider>
    </IntlProvider>
  </RenderPostHog>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
