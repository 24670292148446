import React from "react"
import { useHistory } from "react-router-dom"
import { FormattedMessage } from "react-intl"
import { Button } from "antd"

function FooterLoginPage(props) {
  const { push } = useHistory()
  return (
    <div style={{ textAlign: "center" }}>
      <p>If you already have an account, please login below</p>
      <Button
        shape="default"
        style={{
          color: "white",
          backgroundColor: props.merchantColor,
        }}
        onClick={() => {
          push("/login")
        }}
      >
        <FormattedMessage id="buttons.login" defaultMessage="Login" />
      </Button>
    </div>
  )
}

export default FooterLoginPage
