import moment from "moment"

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  )
}

export function moneyFormat(value) {
  if (value === null) return "$ 0"
  const parsed = value
  return `$ ${parsed}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

export function formatPhoneNumber(phoneNumberString) {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, "")
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`
  }
  return null
}

export function formatLoan(loanText, merchantKey = "") {
  if (loanText === "personal_loan") {
    if (merchantKey === "uown") return "Lease-to-Own"
    return "Retail Installment Loan"
  }
  if (loanText === "credit_line") return "Credit Card"
  if (loanText === "lto") return "Lease-to-Own"
  if (loanText === "lease_to_own") return "Lease-to-Own"
  return "Loan"
}

export function formatLeadStatus(statusText) {
  try {
    if (statusText === "customer") return "Action"
    if (statusText === "review") return "Lender Review"
    if (statusText === "started_application") return "Offers"
    if (statusText === "declined") return "No Offers"
    if (statusText === "approved") return "Offers"
    const statusWords = statusText.split("_")
    const newList = []
    statusWords.forEach((w) => {
      const cap = w[0].toUpperCase() + w.substring(1)
      newList.push(cap)
    })

    return newList.join(" ")
  } catch (error) {
    return statusText
  }
}

export function formatStatus(statusText) {
  try {
    if (statusText === "customer") return "Action"
    if (statusText === "review") return "Lender Review"
    if (statusText === "started_application") return "Applied"
    const statusWords = statusText.split("_")
    const newList = []
    statusWords.forEach((w) => {
      const cap = w[0].toUpperCase() + w.substring(1)
      newList.push(cap)
    })

    return newList.join(" ")
  } catch (error) {
    return statusText
  }
}

export function formatDate(dateString, format = "MMMM Do YYYY") {
  if (!dateString) return "N/A"
  try {
    let date = dateString
    if (typeof dateString === "string") {
      date = moment(dateString)
    }
    return date.format(format)
  } catch (err) {
    return "N/A"
  }
}

const themeColors = {
  paypossible: {
    green: "#19C27F",
    blue: "#6564DB",
    red: "#DB5461",
    orange: "#FA9F42",
    yellow: "#FCBF49",
  },
  general: {
    green: "#52c41a",
    blue: "#1890ff",
    red: "#ff4d4f",
    orange: "#faad14",
    yellow: "#FCBF49",
  },
}

export function getStatusColor(type, status, themeColor) {
  const colors = themeColors.paypossible
  const statusColors = {
    loan: {
      approved: themeColor || colors.green,
      review: themeColor || colors.green,
      funded: themeColor || colors.green,
      applied: themeColor || colors.green,
      declined: colors.red,
      not_funded: colors.red,
      pending: colors.orange,
      customer: colors.orange,
      other: colors.blue,
    },
    order: {
      approved: themeColor || colors.green,
      paid: themeColor || colors.green,
      paid_merchant: themeColor || colors.green,
      cancelled: colors.red,
      refunded: colors.red,
      sent: colors.orange,
      approving: colors.orange,
      pending: colors.blue,
      processing: themeColor || colors.green,
    },
    lead: {
      initial: colors.blue,
      pending: colors.orange,
      processing: colors.orange,
      approved: themeColor || colors.green,
      declined: colors.red,
      selected_offer: themeColor || colors.green,
      started_application: themeColor || colors.green,
      other: colors.blue,
    },
  }

  return statusColors[type][status]
}
