import React, { useEffect, useState, useMemo, useCallback } from "react"
import { Row, Col, Typography, Button, Input } from "antd"
import { useHistory } from "react-router-dom"
import { useApply } from "../../hooks/useApply"
import { useTheme } from "../../hooks/useTheme"
import PoweredBy from "../core/PoweredBy"
import { ProductCard, CategoryCard } from "../../components/cards"
import { getProducts, getCategories } from "../../services/product.service"
import { Spinner } from "../core/Spinner"

const { Text } = Typography

function ProductSelectLander(props) {
  const { merchant, login, img } = props
  const { merchantId, cartsList, cartTotal, updateCartList, removeCartList } =
    useApply()
  const { push } = useHistory()
  const { poweredByLogo } = useTheme()
  const [searchKey, setSearchKey] = useState("")
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [nextUrl, setNextUrl] = useState(null)
  const [preUrl, setPreUrl] = useState(null)
  const [catNextUrl, setCatNextUrl] = useState(null)
  const [catPreUrl, setCatPreUrl] = useState(null)
  const [selectCatId, setSelectCatId] = useState(null)
  const [debouncedSearchKey, setDebouncedSearchKey] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSearch = async () => {
    let uri = `${merchant.business.url}products/?is_active=1&search=${debouncedSearchKey}`
    if (selectCatId) {
      uri = `${merchant.business.url}products/?is_active=1&category_id=${selectCatId}&search=${debouncedSearchKey}`
    }
    setLoading(true)
    const result = await getProducts(uri)
    setProdcutResults(result)
    setLoading(false)
  }

  const handlePagination = async (url) => {
    if (selectCatId) {
      setLoading(true)
      const result = await getProducts(url)
      setProdcutResults(result)
      setLoading(false)
    } else {
      setLoading(true)
      const result = await getCategories(url)
      setCategoryResults(result)
      setLoading(false)
    }
  }

  const setProdcutResults = (result) => {
    if (result.success) {
      setProducts(result.data?.results)
      setNextUrl(result.data?.next)
      setPreUrl(result.data?.previous)
    } else {
      setProducts([])
      setNextUrl(null)
      setPreUrl(null)
    }
  }

  const setCategoryResults = (result) => {
    if (result.success) {
      setCategories(result.data?.results)
      setCatNextUrl(result.data?.next)
      setCatPreUrl(result.data?.previous)
    } else {
      setCategories([])
      setCatNextUrl(null)
      setCatPreUrl(null)
    }
  }

  const selectProduct = (item) => {
    updateCartList(item)
    // if (merchant?.code === "ace-hardware") {
    //   updateCartList(item)
    // } else {
    //   setProduct(item)
    //   if (callback) {
    //     callback(item)
    //   } else {
    //     push("/apply")
    //   }
    // }
  }

  const isExistProduct = useCallback(
    (item) => {
      const data = cartsList.length > 0 ? [...cartsList] : []
      const itemExists = data.some((cartItem) => cartItem.id === item.id)
      if (itemExists) return true
      return false
    },
    [cartsList]
  )

  const handleContinue = async () => {
    push("/apply")
  }

  const displayLogo = useMemo(() => {
    const aceSteps = [
      "Select the products you would like to purchase.",
      "Provide some basic personal details.",
      "Check if you prequalify with no impact to your credit score.",
      "Accept your offer and submit an application.",
      "Review and approve your order  to complete the purchase.",
    ]
    return (
      <>
        <Row align="middle" justify="center">
          <img src={img} width="30%" alt="merchant-logo" />
        </Row>
        <Row align="middle" justify="center">
          <Text
            style={{
              textAlign: "center",
              fontWeight: 600,
              fontSize: 18,
              lineHeight: "28px",
            }}
          >
            {merchant?.name} - {merchant?.address?.city}
          </Text>
        </Row>
        <Row align="middle" justify="center">
          <Text
            style={{
              textAlign: "center",
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            {merchant?.address?.street1}, {merchant?.address?.city},{" "}
            {merchant?.address?.state}, {merchant?.address?.zip}
          </Text>
        </Row>
        <Row
          align="middle"
          style={{
            borderTop: "2px",
            border: "2px solid black",
            borderLeft: 0,
            borderRight: 0,
            marginTop: 15,
            padding: 10,
          }}
        >
          <Text
            style={{
              textAlign: "center",
              fontWeight: 600,
              fontSize: 18,
              lineHeight: "28px",
              width: "100%",
              textDecoration: "underline",
            }}
          >
            Check if you Pre-Qualify
          </Text>
          {aceSteps.map((step, index) => (
            <Row align="middle" style={{ marginTop: 3 }} key={index}>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 15,
                }}
              >
                Step{index + 1}:{" "}
                <Text
                  style={{
                    fontWeight: 400,
                  }}
                >
                  {step}
                </Text>
              </Text>
            </Row>
          ))}
        </Row>

        {cartsList.length > 0 &&
          cartsList.map((cart, index) => (
            <ProductCard
              key={index}
              item={cart}
              removeProduct={removeCartList}
              selectProduct={selectProduct}
              isQTY={true}
            />
          ))}
        <Row
          align="middle"
          justify="space-between"
          style={{
            border: "2px solid black",
            borderTop: cartsList.length > 0 ? 2 : 0,
            borderLeft: 0,
            borderRight: 0,
            marginTop: 15,
            padding: 10,
            fontWeight: 600,
          }}
        >
          <div>Cart Total:</div>
          <div>$ {cartTotal.toLocaleString()}</div>
        </Row>
        <Row
          align="middle"
          justify="center"
          style={{
            marginTop: 20,
          }}
        >
          <Button
            type="primary"
            onClick={handleContinue}
            disabled={cartsList.length === 0}
          >
            Continue
          </Button>
        </Row>
      </>
    )
    // eslint-disable-next-line
  }, [merchant, img, cartsList])

  useEffect(() => {
    if (merchant?.business?.url) {
      ;(async function () {
        const result = await getCategories(merchant.business.url)
        setCategoryResults(result)
      })()
    }
  }, [merchant])

  useEffect(() => {
    if (debouncedSearchKey || selectCatId) {
      handleSearch()
    }
    // eslint-disable-next-line
  }, [debouncedSearchKey, selectCatId])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDebouncedSearchKey(searchKey)
    }, 500)
    return () => clearTimeout(timeoutId)
    // eslint-disable-next-line
  }, [searchKey, 500])

  return (
    <div
      style={{
        backgroundColor: "#ffffff",
        minHeight: "100vh",
        height: "100%",
        position: "relative",
      }}
    >
      <Row align="middle" justify="space-around">
        <Col
          style={{
            backgroundColor: "#ffffff",
            paddingTop: "5rem",
            maxWidth: 380,
            width: "100%",
          }}
        >
          {displayLogo}
          <Row align="middle" justify="center" style={{ paddingTop: 30 }}>
            <Text
              style={{
                textAlign: "center",
                fontWeight: 600,
                fontSize: 18,
                lineHeight: "28px",
              }}
            >
              Please enter the Product Name or SKU of the product you'll be
              purchasing
            </Text>
          </Row>
          <Row
            justify="center"
            style={{ marginTop: 20, height: 45, width: "100%" }}
          >
            <Input
              placeholder="Product Name/SKU"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </Row>
          <Row align="middle" justify="center">
            <Button
              shape="default"
              style={{
                color: "white",
                backgroundColor: "black",
                marginTop: 20,
                padding: "0px 30px",
              }}
              onClick={handleSearch}
            >
              Search
            </Button>
          </Row>
          {selectCatId && (
            <Row align="middle" justify="center">
              <Button shape="link" onClick={() => setSelectCatId("")}>
                {`<`} Back to search categories
              </Button>
            </Row>
          )}
          {loading ? (
            <Spinner />
          ) : (
            <Row
              align="middle"
              justify="center"
              style={{ marginTop: 20, width: "100%" }}
            >
              {searchKey || selectCatId
                ? products.map((item, index) => (
                    <ProductCard
                      key={index}
                      item={item}
                      selectProduct={selectProduct}
                      btnText={"Add to Cart"}
                      disabled={isExistProduct(item)}
                    />
                  ))
                : categories?.map((item, index) => (
                    <CategoryCard
                      item={item}
                      setSelectCatId={setSelectCatId}
                      key={index}
                    />
                  ))}

              {(categories.length > 0 || products.length > 0) && (
                <Row
                  align="middle"
                  justify="space-around"
                  style={{ marginTop: 30 }}
                >
                  <Button
                    type="primary"
                    style={{
                      marginRight: 7,
                    }}
                    disabled={(selectCatId ? preUrl : catPreUrl) === null}
                    onClick={() =>
                      handlePagination(selectCatId ? preUrl : catPreUrl)
                    }
                  >
                    Previous
                  </Button>
                  <Button
                    type="primary"
                    style={{
                      marginLeft: 7,
                    }}
                    disabled={(selectCatId ? nextUrl : catNextUrl) === null}
                    onClick={() =>
                      handlePagination(selectCatId ? nextUrl : catNextUrl)
                    }
                  >
                    Next
                  </Button>
                </Row>
              )}
            </Row>
          )}
          <Row align="middle" justify="center" style={{ paddingTop: 30 }}>
            <Text
              style={{
                textAlign: "center",
                fontWeight: 500,
                fontSize: 15,
                lineHeight: "28px",
              }}
            >
              {merchantId === "trek" && (
                <>
                  Checking eligibility{" "}
                  <Text
                    style={{
                      fontWeight: 700,
                    }}
                  >
                    will not impact
                  </Text>{" "}
                  your credit score
                </>
              )}
            </Text>
          </Row>
          {poweredByLogo && <PoweredBy />}
        </Col>
      </Row>
      <Row align="middle" justify="center" style={{ marginTop: 30 }}>
        {login && login}
      </Row>
    </div>
  )
}

export default ProductSelectLander
